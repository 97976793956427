import React from 'react';
import { Link } from 'gatsby';

import { Layout, Products } from '../../components/common';
import { TelegramChannel } from '../../components/common/Promo';
import { Container, Paragraph, SubHeading } from '../../pageStyles'

const Index = () => {
    return (
        <Layout promotion={<TelegramChannel/>}>
            <Container>
                <Paragraph>
                    Ecco  i prodotti che NorthFPV consiglia a tutti, li abbiamo suddivisi in categorie di modo che sia facile trovare quello che serve.
                </Paragraph>
                <SubHeading>
                    Come scegliamo i prodotti?
                </SubHeading>
                <Paragraph>
                    Tutto ciò che consigliamo, è stato testato personalmente da noi, o dai nostri membri del <Link href="/club">Club</Link>. Questo ci permette di consigliare prodotti affidabili e soprattutto sulla quale abbiamo installato personalmente, in questo modo, se hai dubbi o problemi puoi chiedere aiuto sul <Link href="https://t.me/north_fpv">gruppo della community</Link>.
                </Paragraph>
            </Container>
            <Container>
                <Products/>
            </Container>
        </Layout>
    );
}

export default Index;